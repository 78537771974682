import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { API_SERVER } from "./settings";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: {
      //useSuspense: false,
    },
    backend: {
        loadPath: `${API_SERVER}i18n?ns={{ns}}&lng={{lng}}`,
        allowMultiLoading: false,
        crossDomain: false,
    },
    detection: {
        // order and from where user language should be detected
        order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
      
        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
      
        // optional expire and domain for set cookie
        cookieMinutes: 10,
        //cookieDomain: '127.0.0.1',
      
        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement,
      
        // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        cookieOptions: { path: '/', sameSite: 'strict' }
    },
    fallbackLng: 'en',
    debug: true,
    ns: ['layout'],
    defaultNS: 'layout',
    interpolation: {
      escapeValue: false,
    }
  });


export default i18n;