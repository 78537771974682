import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactFlagsSelect from 'react-flags-select';
import { enGB, fr } from 'date-fns/locale';
import { registerLocale, setDefaultLocale } from "react-datepicker";

const LangSelector = () => {
  const { t, i18n } = useTranslation();
  let storedLng = localStorage.getItem('i18nextLng');
  let defaultCountry = storedLng==='en'?'US':'FR';
  const [selectedLang, setSelectedLang] = useState(defaultCountry);
  registerLocale('en', enGB);
  registerLocale('fr', fr);

  setDefaultLocale(localStorage.getItem('i18nextLng'));

  const changeLanguage = (code) => {
    var value = code!=='FR'?'en':'fr';
    i18n.changeLanguage(value);
    setDefaultLocale(value);
  }
 
  return (
    <ReactFlagsSelect
        countries={["US", "FR"]}
        customLabels={{"US": "English", "FR": "Français"}}
        selected={selectedLang}
        onSelect={code => {
            setSelectedLang(code);
            changeLanguage(code);
        }}
        placeholder= {t('select-language')}
    />
  )
}
 
export default LangSelector;