import React from "react";
import Modal from 'react-bootstrap/Modal';

export const SPBlockedModal = (props) => {

    return (
      <Modal
        {...props}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { props.nocontainer==="true" ?
            props.children
          :
            <>
              {props.children}
            </>
          }
 
        </Modal.Body>
        <Modal.Footer>          
        </Modal.Footer>
      </Modal>
    );
  }