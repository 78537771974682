import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Home, Person, Settings, MenuOutline, TrendingUp, Pricetag, ShareSocial } from 'react-ionicons'
import Menu from 'react-burger-menu/lib/menus/slide'
import { NavLink } from 'react-router-dom';

export const MenuLeft = (props) => {
  const { t } = useTranslation('layout');
  const [ opened, setOpened ] = useState(localStorage.getItem('sp-menuopened')?(localStorage.getItem('sp-menuopened') === "true" ? true : false) : true);
  const handleToggle = () => {
    if (opened) setOpened(false);
    else setOpened(true);
    localStorage.setItem('sp-menuopened', !opened);
  };

  return (
    <div className="left-menu-container">
        <div className="left-menu-button-container bg-light">
            <MenuOutline className="left-menu-button" color={'#ffffff'} title={t('my-menu')} height="36px" width="36px" onClick={() => handleToggle()}/>
        </div> 
        <Menu 
        isOpen={props.user?opened:false}
            noOverlay 
            customBurgerIcon={false}
            customCrossIcon={false}  
          >          
            <NavLink className="bm-item menu-item" to="/profile">
              <Person color='#ffffff' title={t('profile')} height="25px" width="25px"/>&nbsp;            
              {t('profile')}
            </NavLink>
            <NavLink className="bm-item menu-item" to={(props.user.subscription)?"/dashboard":"/subscribe"}>
              <Home color='#ffffff' title={t('dashboard')} height="25px" width="25px"/>&nbsp;
              {t('dashboard')}
            </NavLink>
            <NavLink className="bm-item menu-item" to={(props.user.subscription)?"/account":"/subscribe"}>
              <Settings color='#ffffff' title={t('accounts')} height="25px" width="25px"/>&nbsp;          
              {t('accounts')}
            </NavLink>
            <NavLink className="bm-item menu-item" to={(props.user.subscription)?"/budget":"/subscribe"}>
              <Pricetag color='#ffffff' title={t('budget')} height="25px" width="25px"/>&nbsp;
              {t('budget')}
            </NavLink>                       
            <NavLink className="bm-item menu-item" to={(props.user.subscription)?"/projections":"/subscribe"}>
              <TrendingUp color='#ffffff' title={t('projections')} height="25px" width="25px"/>&nbsp;
              {t('projections')}
            </NavLink> 
            <NavLink className="bm-item menu-item" to="/shares">
              <ShareSocial color='#ffffff' title={t('shares')} height="25px" width="25px"/>&nbsp;
              {t('shares')}
            </NavLink>                                         
        </Menu>
    </div>
  )
};