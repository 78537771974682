import React from "react";
import Modal from 'react-bootstrap/Modal';
import { usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from 'react-loader-spinner';

export const LoaderIndicator = props => {
    const { promiseInProgress } = usePromiseTracker();

    return (
      promiseInProgress &&
      <Modal
        show={true}
        size="lg"
        backdrop={false}
        dialogClassName="loader-modal"
        centered
      >
      <Modal.Body>
        <ThreeDots 
          height="80" 
          width="80" 
          radius="9"
          color="#4fa94d" 
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName="loader-container"
          visible={true}
        />
      </Modal.Body>
    </Modal>
    );  
} 