import React, { useEffect } from 'react'
import { lazyWithRetry } from "./common/tools/lazyWithRetry";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import { Layout as Public, Layout as Private } from './common/components/Layout';

const Home = lazyWithRetry(() => import('./common/components/Home'));
const Message = lazyWithRetry(() => import('./common/components/Message'));
const Help = lazyWithRetry(() => import('./common/components/Help'));
const Cookies = lazyWithRetry(() => import('./common/components/Cookies'));
//const Blank = lazyWithRetry(() => import('./common/components/Blank'));
const Register = lazyWithRetry(() => import('./user/components/Register'));
const Invitation = lazyWithRetry(() => import('./user/components/Invitation'));
const Activate = lazyWithRetry(() => import('./user/components/Activate'));
const Reset = lazyWithRetry(() => import('./user/components/Reset'));
const Password = lazyWithRetry(() => import('./user/components/Password'));
const Profile = lazyWithRetry(() => import('./user/components/Profile'));
const Dashboard = lazyWithRetry(() => import('./account/components/Dashboard'));
const Accounts = lazyWithRetry(() => import('./account/components/Accounts'));
const Budget = lazyWithRetry(() => import('./account/components/Budget'));
const Entries = lazyWithRetry(() => import('./account/components/Entries'));
const Recurrents = lazyWithRetry(() => import('./account/components/Recurrents'));
const Projections = lazyWithRetry(() => import('./account/components/Projections'));
const Subscribe = lazyWithRetry(() => import('./subscription/components/Subscribe'));
const Contact = lazyWithRetry(() => import('./support/components/Contact'));
const Tickets = lazyWithRetry(() => import('./support/components/Tickets'));
const Disclaimer = lazyWithRetry(() => import('./common/components/Disclaimer'));
const Shares = lazyWithRetry(() => import('./account/components/Shares'));
const Share = lazyWithRetry(() => import('./account/components/Share'));

const App = () => {

  const location = useLocation();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behaviour: "smooth"
    });
  }, [location.pathname]);

  return (
    <>
      <div>
        <Routes>
          <Route element={<Public private={false}/>}>
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/Message" element={<Message />} />
            <Route exact path="/help" element={<Help />} />            
            <Route exact path="/Cookies" element={<Cookies />} />            
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/invitation" element={<Invitation />} />            
            <Route exact path="/activate" element={<Activate />} />   
            <Route exact path="/reset" element={<Reset />} />  
            <Route exact path="/password" element={<Password />} />
            <Route exact path="/contact" element={<Contact />} />  
            <Route exact path="/disclaimer" element={<Disclaimer />} />
            <Route path={"/"} element={<Home />} />          
          </Route>
          <Route element={<Private private={true}/>}>     
            {/*<Route exact path='/blank' element={<Blank/>} />*/}
            <Route exact path="/support/ticket" element={<Tickets />} />                             
            <Route exact path='/subscribe' element={<Subscribe/>} />
            <Route exact path='/profile' element={<Profile/>} />
            <Route exact path='/dashboard' element={<Dashboard/>} /> 
            <Route exact path='/account' element={<Accounts/>} />
            <Route exact path='/budget' element={<Budget/>} />            
            <Route exact path='/account/entries' element={<Entries futures={false}/>} />
            <Route exact path='/account/futures' element={<Entries futures={true}/>} />
            <Route exact path='/account/recurrents' element={<Recurrents/>} />            
            <Route exact path='/projections' element={<Projections/>} /> 
            <Route exact path='/shares' element={<Shares/>} />
            <Route exact path='/share' element={<Share/>} />             
          </Route>          
        </Routes>
      </div>      
    </>
  );
};

export default App;
