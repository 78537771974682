import React, { useState, useEffect } from "react";
import LangSelector from './LangSelector';
import logo from '../../img/splogo.png';
import { useTranslation } from 'react-i18next';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { CardOutline } from 'react-ionicons';
import { NavLink } from 'react-router-dom';
import { MenuLeft } from "./MenuLeft";
import UserService from "../../user/services/user.service";
import { toast } from 'react-toastify';
import Login from "../../user/components/Login";
import { SPBlockedModal as LoginModal } from "../modals/SPBlockedModal";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../user/hooks/useAuth";
import { CompassOutline } from 'react-ionicons';

export const MenuTop = (props) => {
  const { t } = useTranslation('layout');
  const { user, setUser } = useAuth();  
  const [ loginModalShow, setLoginModalShow ] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("disconnected", () => {
      if (props.private) {
        setLoginModalShow(true);
      }      
    });
  }, [user]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.private && !user) {
        setLoginModalShow(true);
    }
  }, [user, location.pathname]);// eslint-disable-line react-hooks/exhaustive-deps

  const logOut = () => {
    UserService.logout().then(
      (response) => {
        if (response.detail[0].success) {
          toast.success(t(response.detail[0].success));
        } else {
          toast.error(t(response.detail[0].error));
        }
      }
    );
    props.setUser(false);
    localStorage.removeItem("projection-account");
    localStorage.removeItem("projection-projection");  
    localStorage.removeItem("user");
  };

  const handleLogin = () => {
    setLoginModalShow(true);
  };

	return (
    <div className="menu-top">
      <Navbar bg="white" expand="lg">
        <div className="nav-stuff">
          <Navbar.Brand href="/">
            <img alt="Simple Projection" src={logo} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="default-navbar-nav" />          
          <div className="nav-stuff-items"> 
            <Navbar.Collapse id="default-navbar-nav">
              <Nav>
                <NavLink className="nav-item btn btn-light" to={props.user ? "/support/ticket" : "/contact"}>
                  {props.user ? t('support') : t('contact')}
                </NavLink>   
                { props.user &&
                  <NavLink className="nav-item btn btn-light" to="/account" onClick={() => props.setAssistant(true)}>
                    {t('assistant')}&nbsp;<CompassOutline color={'#000000'} title={t('open-assistant')} height="30px" width="30px"/>
                  </NavLink>  
                }                   
              </Nav>
              <Nav className="ms-auto">
              { props.user ? 
                <>
                  { (!props.user.subscription) &&
                    <NavLink className="nav-item btn btn-light" to="/subscribe">
                      {t('subscribe')}
                      &nbsp;<CardOutline color={'#000000'} title={t('subscribe')} height="25px" width="25px"/>
                    </NavLink>
                  }        
                  <NavLink className="nav-item btn btn-secondary"  to="/home" onClick={logOut}>
                    {t('logout')}
                  </NavLink>          
                </>
              :
                <>
                  <NavLink className="nav-item btn btn-light" to="/register">
                    {t('register')}
                  </NavLink>              
                  <NavLink className="nav-item btn btn-secondary" onClick={handleLogin}>                
                    {t('login')}
                  </NavLink>                           
                </>
              }
              </Nav>
            </Navbar.Collapse>
          </div>
        </div>
        <div className="message-zone">
          <div className="language-selector">
            <LangSelector/>
          </div>
          { props.user && 
            <MenuLeft user={props.user}/>
          } 
        </div>
      </Navbar>
      <LoginModal
        show={loginModalShow}
        onHide={() => setLoginModalShow(false)}
        title={t('login')}
      >
        <Login      
          setUser={setUser}
          setLoginModalShow={setLoginModalShow}
        />
      </LoginModal>
    </div>
  );
}